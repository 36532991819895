<template>
  <div id="travelArrangement">
     <img  :class="windowWidth >= 1200? 'SliderStyle':'SliderStyleMobile'"  alt="" src="@/assets/images/Patient/travelbanner.jpg" />
       <div style="height:47vh" v-if="windowWidth >= 1200">
     </div>

    <div class="vx-row">
      <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <p class="text-xl">
        {{ $t("ServicesProvidedToOurPatients") }} 
      </p>
    </div>
   
    <div class="ml-5 mr-5">
    
      <div class="ml-5 mr-5 mt-10" style="font-size: 20px;">
        <checkpoint v-for="item in points" :data="item" :key="item.title" />
      </div>
      <p
        class="fontMedium ml-5 mr-5 mt-10"
        style="font-size: 15px; color: black"
      >
        
        {{$t('sendUS')}}
        <span style="font-size: 15px" class="primarycolor">
          <a href="mailto:travel@doclinia.com">travel@doclinia.com</a>
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import checkpoint from "../../components/check_point.vue";
export default {
  components: {
    checkpoint,
  },
  data() {
    return {
      EnglishLan:false,
      points: [
        {
          title:this.$i18n.t("serviceNote1"),
          icon: "visa.png",
        },
        {
          title:this.$i18n.t("Flights"),

          icon: "flight.png",
        },
        {
          title:this.$i18n.t("Transfers"),
          icon: "transfer.png",
        },
        {
          title:this.$i18n.t("serviceNote2"),
          icon: "hotel.png",
        },
      ],
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  computed:{
        windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  created(){
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

  },
    mounted(){
        window.onscroll = function() {myFunction()};
        
        function myFunction() {
           var navbar = document.getElementById("navbarStick");
        var sticky = navbar.offsetTop;
          if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
          } else {
            navbar.classList.remove("sticky");
          }
        }
  },
};
</script>
<style  >
#travelArrangement .SliderStyleMobile{
   width: 100%;
   z-index: 1;
   
}
#travelArrangement .SliderStyle{
    position: absolute;
    top: 0px;
    z-index: 90;
    left: 89px;
    width: 90vw;
    max-height:49vh;
 object-fit: cover;
}
</style>