<template>
      <div class="vx-row mt-2">
       
        <img src="@/assets/images/home-assets/check.png" style="width: 30px;height: 30px;"  v-if="!data.icon"/>
        <img alt="" v-else :src="require(`@/assets/images/travel-icon/${data.icon}`)" style="width: 30px;height: 30px;"  />

        <p class="vx-col lg:w-3/4 fontMedium primarycolor" style="width:80%;font-family:Sans-serif" v-if="data.title" >
           {{data.title}}
        </p>
         <p class="ml-2 vx-col lg:w-3/4 sm:w-3/4 fontMedium" style="width:80%;" v-if="data.Name"  >{{data.Name}}</p>
         <p class="ml-2 vx-col lg:w-3/4 fontMedium" style="width:80%;" v-if="data.Description"  >{{data.Description}}</p>
          <div  v-if="haveRemove" style="border-radius:50px;width: 20px; height: 20px;curser:pointer" class="primaryBackColor p-1">
                <feather-icon @click="$emit('remove')" style="color: white;width: 15px;height: 15px;" icon="XIcon"></feather-icon>
          </div>
         <div class="editButton primaryBackColor" v-if="hameEdit" @click="$emit('editTreatment')">
            {{$t("Details")}}
         </div>
     </div>
</template>
<script>
export default {
    props: {
      data: {
        type: Object,
        default: () => {}
      },
      haveRemove: {
        type: Boolean,
        default: false
      },
       hameEdit: {
        type: Boolean,
        default: false
      }
  },
}
</script>

<style>
  .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%
}
.editButton {
  color: white;
  padding: 5px;
  border-radius: 5px;
}

</style>