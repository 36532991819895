import { render, staticRenderFns } from "./check_point.vue?vue&type=template&id=259ff180"
import script from "./check_point.vue?vue&type=script&lang=js"
export * from "./check_point.vue?vue&type=script&lang=js"
import style0 from "./check_point.vue?vue&type=style&index=0&id=259ff180&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports